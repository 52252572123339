<template>
  <div>
    <nav class="navbar navbar-expand-lg fixed-top">
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarText"
        aria-controls="navbarText"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <a class="navbar-brand" href="/" v-if="channelLogo">
        <image-component
          :path="channelLogo"
          class="main-logo"
          alt="Main logo"
        />
      </a>
      <div class="collapse navbar-collapse" id="navbarText">
        <ul class="navbar-nav">
          <template v-for="(item, index) in getSubHeaderLinks">
            <li v-if="item.children" :key="index" class="nav-item">
              <ul
                class="nav-link"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {{
                  item.name
                }}
              </ul>
              <div
                class="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <template v-for="(itemChild, indexChild) in item.children">
                  <router-link
                    class="dropdown-item"
                    :to="itemChild.url"
                    :key="indexChild"
                    exact
                  >
                    {{ itemChild.name }}
                  </router-link>
                </template>
              </div>
            </li>
            <li class="nav-item" :key="index" v-else>
              <router-link class="nav-link" :to="item.url" :key="index" exact>
                {{ item.name }}
              </router-link>
            </li>
          </template>
          <template v-if="authUser">
            <li class="nav-item">
              <a class="nav-link" href="#" @click.prevent="logout">
                {{ $t('Logout') }}
              </a>
            </li>
          </template>
          <template v-else>
            <li class="nav-item">
              <router-link class="nav-link" to="/login">
                {{ $t('Login') }}
              </router-link>
            </li>
          </template>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { MENU_POSITION } from '../../const';
import ImageComponent from '../../components/base-components/ImageComponent';

export default {
  name: 'HeaderEntertainmentComponent',
  components: { ImageComponent },
  data() {
    return {
      MENU_POSITION: MENU_POSITION,
    };
  },
  methods: {
    ...mapActions(['DO_LOGOUT', 'GET_API_KEY']),
    logout() {
      this.DO_LOGOUT().then(() => {
        this.$store.commit('RESET_STATE');
        this.$router.go(this.$router.currentRoute);
      });
    },
  },
  watch: {
    $route() {
      const element = document.querySelector('#navbarText');
      element.classList.remove('show');
    },
  },
  computed: {
    ...mapState({
      channelMenu: (state) => state.channel.menu,
      authUser: (state) => state.auth.user,
      channelSettings: (state) => state.channel.settings,
    }),
    channelLogo() {
      return this.channelSettings?.content?.['channel-logo'];
    },
    getSubHeaderLinks() {
      console.log('getSubHeaderLinks', this.channelMenu);
      if (this.channelMenu) {
        return this.channelMenu.filter((item) => {
          if (item.position === 'header') {
            return item;
          }
          if (item.position === 'both') {
            return item;
          }
        });
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/css/colors';
@import 'src/assets/css/mixins';

.navbar {
  padding: 0 2em;
  font-size: 0.8rem;
  letter-spacing: 2px;

  .navbar-toggler {
    color: rgba($text-primary-rgb, 0.5);
    border-color: rgba($button-primary-rgb, 0.1);
  }

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }
}

.navbar-nav {
  align-items: center;
}

.main-logo {
  max-height: 80px;
  width: 210px;
}

.nav-item {
  position: relative;
  text-transform: uppercase;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 1.8rem;
}

.dropdown-menu {
  position: absolute;
  background-color: transparent;
  border: none;
  width: 220px;
  left: 0;
  padding: 0;
}

.dropdown-item {
  font-size: 0.75rem;
  font-weight: 700;
  color: $text-tertiary-dark;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 0;
  letter-spacing: 0;
}

.navbar-collapse {
  justify-content: flex-end;
}

div {
  .navbar {
    background-color: $card-background;
    height: 80px;
  }

  .nav-link {
    color: black;
  }

  .dropdown-item {
    color: #333;
  }

  .dropdown-menu {
    border-top: 2px solid $text-tertiary-dark;
    background-color: $secondary-background;
    box-shadow: 0 0 20px rgba($button-primary-rgb, 0.2);
  }
}

@include media-min(md) {
  .transparent {
    .navbar {
      background-color: transparent;
    }

    .nav-link {
      color: #eeeeee;
      margin-top: 1px;
    }

    .dropdown-item {
      color: $text-tertiary-light;

      &:focus,
      &:hover {
        background-color: $text-tertiary-middle;
      }
    }

    .dropdown-menu {
      border-top: 2px solid $error;
      background-color: $text-tertiary-dark;
    }
  }
}

@include media-max(md) {
  .nav-item,
  .dropdown-item {
    border-bottom: 1px solid rgba($text-tertiary-dark, 0.3);
    width: 100%;
    text-align: start;
  }
  .nav-item:last-child,
  .dropdown-item:last-child {
    border-bottom: none;
  }
  .dropdown-menu {
    width: 100%;
  }
  div {
    .dropdown-menu {
      position: relative;
      border-top: none;
      background-color: $secondary-background;
      box-shadow: none;
    }
  }
}
</style>
